<template>
    <section class="c-dashboard">
        <app-intro>
            <h1>Configuratie <span v-if="!loading">- {{ details.name }}</span></h1>
            <p>Samenvatting van configuratie</p>
        </app-intro>

        <div class="c-loading" v-if="loading">
            <spinner/>
            Bezig met laden van de configuratie...
        </div>

        <transition-group class="c-configuration" name="fade" tag="section" v-if="!loading">
            <div class="c-configuration__body || c-grid-list" key="configuration-body">
                <div class="table" v-if="configuration.style">
                    <div class="table__thead">
                        <div class="table__tr">
                            <div class="table__th">Stijl</div>
                            <div class="table__th"></div>
                        </div>
                    </div>
                    <div class="table__tbody">
                        <div class="table__tr" key="style">
                            <div class="table__td">{{ configuration.style.name }}</div>
                            <div class="table__td"></div>
                        </div>
                    </div>
                </div>

                <div class="table" v-if="configuration.role">
                    <div class="table__thead">
                        <div class="table__tr" v-for="role in configuration.role" :key="'thead-' + role.id">
                            <div class="table__th">Functies</div>
                            <div class="table__th">Oppervlakte</div>
                        </div>
                    </div>
                    <div class="table__tbody">
                        <div class="table__tr" v-for="role in configuration.role" :key="'tbody-' + role.id">
                            <div class="table__td">{{ role.name }}</div>
                            <div class="table__td">{{ Math.round(role.quantity) }} m<sup>2</sup></div>
                        </div>
                    </div>
                </div>

                <div class="table" v-if="configuration.size">
                    <div class="table__thead">
                        <div class="table__tr">
                            <div class="table__th">Afmetingen</div>
                            <div class="table__th"></div>
                        </div>
                        <div class="table__tr">
                            <div class="table__th">Afmetingen</div>
                            <div class="table__th"></div>
                        </div>
                        <div class="table__tr">
                            <div class="table__th">Afmetingen</div>
                            <div class="table__th"></div>
                        </div>
                        <div class="table__tr">
                            <div class="table__th">Afmetingen</div>
                            <div class="table__th"></div>
                        </div>
                    </div>
                    <div class="table__tbody">
                        <div class="table__tr" key="size-length">
                            <div class="table__td">Totale lengte</div>
                            <div class="table__td">{{ currentLength }} m</div>
                        </div>
                        <div class="table__tr" key="size-width">
                            <div class="table__td">Totale breedte</div>
                            <div class="table__td">{{ currentWidth }} m</div>
                        </div>
                        <div class="table__tr" key="size-surface">
                            <div class="table__td">Totale oppervlakte</div>
                            <div class="table__td">{{ configuration.surfaceArea }} m<sup>2</sup></div>
                        </div>
                        <div class="table__tr" key="size-height">
                            <div class="table__td">Pand hoogte</div>
                            <div class="table__td">{{ height }} m</div>
                        </div>
                    </div>
                </div>

                <div class="table" v-if="configuration.exterior">
                    <div class="table__thead">
                        <div class="table__tr" v-for="exterior in configuration.exterior" :key="'thead-' + exterior.id">
                            <div class="table__th">Exterieur</div>
                            <div class="table__th">Aantal</div>
                        </div>
                    </div>
                    <div class="table__tbody">
                        <div class="table__tr" v-for="exterior in configuration.exterior" :key="'tbody-' + exterior.id">
                            <div class="table__td">{{ exterior.name }}</div>
                            <div class="table__td" v-show="!exterior.is_toggle">{{ exterior.quantity }}x</div>
                            <div class="table__td" v-show="exterior.is_toggle">{{ exterior.quantity === 1 ? 'Ja' : 'Nee' }}</div>
                        </div>
                    </div>
                </div>

                <div class="table" v-if="configuration.interior">
                    <div class="table__thead">
                        <div class="table__tr">
                            <div class="table__th">Interieur</div>
                            <div class="table__th"></div>
                        </div>
                    </div>
                    <div class="table__tbody">
                        <div class="table__tr" key="style">
                            <div class="table__td">{{ configuration.interior.name }}</div>
                            <div class="table__td"></div>
                        </div>
                    </div>
                </div>

                <div class="table" v-if="configuration.installation">
                    <div class="table__thead">
                        <div class="table__tr">
                            <div class="table__th">Installatie</div>
                            <div class="table__th"></div>
                        </div>
                    </div>
                    <div class="table__tbody">
                        <div class="table__tr">
                            <div class="table__td">{{ configuration.installation.name }}</div>
                            <div class="table__td"></div>
                        </div>
                    </div>
                </div>
            </div>

            <aside class="c-configuration__aside || c-grid-list" key="configuration-aside">
                <div class="table table--3/1 table--full-width">
                    <div class="table__thead">
                        <div class="table__tr">
                            <div class="table__th">Algemeen</div>
                        </div>
                    </div>
                    <div class="table__tbody">
                        <div class="table__tr">
                            <div class="table__td">Prijs</div>
                            <div class="table__td">{{ currency(details.price) }}</div>
                        </div>
                        <div class="table__tr">
                            <div class="table__td">Status</div>
                            <div class="table__td" v-show="details.status.id !== 100" style="display: flex; justify-content: space-between;">
                                <!--<strong v-if="!statusChange">{{ details.status.label }}</strong>-->

                                <select class="form-control form-control--small" v-model="selectedStatus">
                                    <option v-bind:value="option.id" :disabled="option.id < details.status.id" v-for="option in status" :key="option.id">{{ option.label }}</option>
                                </select>

                                <!--<app-button class="c-btn c-btn&#45;&#45;square c-btn&#45;&#45;small" v-on:click.native.prevent="toggleStatus" v-if="!statusChange">
                                    <svg width="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path>
                                    </svg>
                                </app-button>-->

                                <app-button class="c-btn c-btn--success c-btn--square c-btn--small" v-on:click.native.prevent="updateStatus">
                                    <svg width="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM272 80v80H144V80h128zm122 352H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h42v104c0 13.255 10.745 24 24 24h176c13.255 0 24-10.745 24-24V83.882l78.243 78.243a6 6 0 0 1 1.757 4.243V426a6 6 0 0 1-6 6zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 128c-22.056 0-40-17.944-40-40s17.944-40 40-40 40 17.944 40 40-17.944 40-40 40z"></path>
                                    </svg>
                                </app-button>
                            </div>
                            <div class="table__td" v-show="details.status.id === 100">
                                <strong class="u-text-success">{{ details.status.label }}</strong>
                            </div>
                        </div>
                        <div class="table__tr">
                            <div class="table__td">Datum</div>
                            <div class="table__td">{{ details.created.friendly }}</div>
                        </div>
                    </div>
                </div>

                <div class="table">
                    <div class="table__thead">
                        <div class="table__tr">
                            <div class="table__th">Accountgegevens</div>
                            <div class="table__th"></div>
                        </div>
                    </div>
                    <div class="table__tbody">
                        <div class="table__tr">
                            <div class="table__td">Naam</div>
                            <div class="table__td">{{ account.details.name }}</div>
                        </div>
                        <div v-show="account.details.company" class="table__tr">
                          <div class="table__td">Bedrijfsnaam</div>
                          <div class="table__td">{{ account.details.company }}</div>
                        </div>
                        <div class="table__tr">
                            <div class="table__td">Adres</div>
                            <div class="table__td">{{ account.details.address }}</div>
                        </div>
                        <div class="table__tr">
                            <div class="table__td">Postcode</div>
                            <div class="table__td">{{ account.details.postcode }}</div>
                        </div>
                        <div class="table__tr">
                            <div class="table__td">Plaats</div>
                            <div class="table__td">{{ account.details.city }}</div>
                        </div>
                        <div class="table__tr">
                          <div class="table__td">Provincie</div>
                          <div class="table__td">{{ account.details.county }}</div>
                        </div>
                        <div class="table__tr">
                            <div class="table__td">E-mail</div>
                            <div class="table__td">{{ account.email }}</div>
                        </div>
                        <div class="table__tr">
                            <div class="table__td">Telefoon</div>
                            <div class="table__td">{{ account.details.phone }}</div>
                        </div>
                        <div class="table__tr">
                            <div class="table__td">Land</div>
                            <div class="table__td">{{ account.details.country }}</div>
                        </div>
                    </div>
                </div>

                <div class="table table--full-width" v-if="configurations.length">
                    <div class="table__thead">
                        <div class="table__tr">
                            <div class="table__th">Andere configuraties</div>
                        </div>
                    </div>
                    <div class="table__tbody">
                        <div class="table__tr" v-for="config in configurations" :key="'config-' + config.id">
                            <div class="table__td">{{ config.name }}</div>
                            <div class="table__td">
                                <a :href="'/admin/configuration/' + config.id">Bekijk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>

            <div key="link">
                <router-link to="/admin/configurations">Terug naar overzicht</router-link>
            </div>

            <template v-if="errors.length > 0">
                <inline-alert type="error" key="error">Er ging iets mis bij het ophalen van de configuratie omdat {{ computedErrors }} leeg is</inline-alert>
            </template>
        </transition-group>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import AppButton from '../components/app/AppButton.vue';
    import AppIntro from '../components/app/AppIntro.vue';
    import InlineAlert from '../components/InlineAlert.vue';
    import Spinner from '../components/Spinner.vue';
    import currency from '../helpers/currency';
    import { accountService, configurationService } from '../services';

    export default {
        components: {
            AppButton,
            Spinner,
            InlineAlert,
            AppIntro,
        },
        data () {
            return {
                loading: true,
                statusChange: false,
                selectedStatus: '',
                configuration: {},
                details: {},
                account: {},
                configurations: [],
                errors: [],
            };
        },
        watch: {
            statusChange () {
                this.$store.dispatch('configuration/status');
            },
        },
        computed: {
            ...mapState({
                status: (state) => state.configuration.status,
            }),
            currentLength () {
                const { length } = this.configuration.style;
                const size = this.configuration.size.find((item) => item.id === 'length');
                return size ? size.quantity : length.min;
            },
            currentWidth () {
                const { width } = this.configuration.style;
                const size = this.configuration.size.find((item) => item.id === 'width');
                return size ? size.quantity : width.min;
            },
            height () {
                const { height } = this.configuration.style;
                return height;
            },
            computedErrors () {
                if (this.errors.length > 0)
                {
                    const { errors: errors1 } = this;
                    const errors = errors1.reverse();

                    if (errors.length === 1)
                    {
                        return `${errors} is `;
                    }

                    if (errors.length > 1)
                    {
                        errors.splice(errors.length - 1, 0, 'en');
                    }

                    if (errors.length > 3)
                    {
                        return `${errors.join(', ').replace(', en,', ' en')} zijn`;
                    }

                    return `${errors.join(' ')} zijn`;
                }

                return false;
            },
        },
        async created () {
            // get the configuration
            await this.getConfiguration();

            // get account
            await this.getAccount();

            // set status
            this.selectedStatus = typeof this.details.status !== 'undefined' ? this.details.status.id : '';

            // reset errors
            this.errors = [];

            // check for errors
            if (Object.values(this.configuration).length)
            {
                if (this.configuration.installation.length === 0) this.errors.push('installatie');
                // if (this.configuration.interior.length === 0) this.errors.push('interieur');
                if (this.configuration.exterior.length === 0) this.errors.push('exterieur');
                if (this.configuration.role.length === 0) this.errors.push('functie');
                if (this.surfaceArea === 0) this.errors.push('afmetingen');
            }
            else
            {
                this.errors.push('de configuratie');
            }

            // reset loading
            this.loading = false;
        },
        methods: {
            currency (price) {
                return currency(price);
            },
            toggleStatus () {
                this.statusChange = !this.statusChange;
            },
            updateStatus () {
                if (typeof this.selectedStatus === 'number' && this.details.id)
                {
                    return configurationService
                        .updateStatus(this.details.id, { status: this.selectedStatus })
                        .then(() => {
                            this.getConfiguration();
                            this.toggleStatus();
                        }).catch(() => {
                            this.loading = false;
                        });
                }

                return false;
            },
            getConfiguration () {
                if (this.$route.params.id)
                {
                    return configurationService
                        .get(this.$route.params.id)
                        .then((result) => {
                            this.details = result;
                            this.configuration = JSON.parse(result.configuration.json);
                        }).catch(() => {
                            this.loading = false;
                        });
                }

                return false;
            },
            getAccount () {
                if (this.details.user_id && this.details.id)
                {
                    return accountService
                        .get(this.details.user_id)
                        .then((account) => {
                            this.account = account;
                            this.configurations = [];

                            // check if the account has more configurations
                            if (account.configurations.total > 1)
                            {
                                // filter out the current configuration
                                this.configurations = account.configurations.list.filter((conf) => Number(conf.id) !== Number(this.details.id));
                            }
                        }).catch(() => {
                            this.loading = false;
                        });
                }

                return false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/essentials";

    .form-control--small {
        height: rem(30);
        padding: rem(5);
        width: 75%;

        option:disabled {
            color: $text-muted;
        }
    }

    .table {
        grid-template-columns: 1fr;

        @include media-breakpoint-only(sm) {
            margin: 0;
            max-width: 100%;
        }

        &__tr {
            grid-template-columns: 1fr 1fr;

            @include media-breakpoint-down(sm) {
                .table__thead & {
                    display: none;

                    &:first-child {
                        display: grid;
                    }
                }
            }

            .table--3\/1 & {
                grid-template-columns: 25% 1fr;
            }
        }
    }

    .c-configuration {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "configuration-body"
                             "configuration-aside";
        grid-gap: rem(15);

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr rem(400);
            grid-template-areas: "configuration-body configuration-aside";
            grid-gap: rem(30);
        }

        &__body {
            grid-area: configuration-body;
        }

        &__aside {
            grid-area: configuration-aside;
        }
    }
</style>
